/* ============
 * Mutations for the offers module
 * ============
 *
 * The mutations that are available on the
 * offers module.
 */

import {
  UPDATE_PLANS,
  PUT_STRIPE,

  SET_ACTIVE_OFFER,
  REMOVE_PLANS,
  SET_CLOUD_PAYMENTS_PLAN,
  SET_ONE_MONTH_OFFER_PRICE,
  SET_PROMO_USER,
  REMOVE_SOC_PAY,
  SET_TIER_TYPE,
  SET_MESSAGE_PARAMS,
  SET_CLOSING_PAYMENT_COUNT,
  SET_SKELETON_LOAD_STATUS,
  SET_PLANS_LAST_PARAMS
} from './mutation-types'

export default {
  [UPDATE_PLANS] (state, params) {
    state.offerCards = params
  },
  [PUT_STRIPE] (state, params) {
    state.token = params
  },
  [SET_ACTIVE_OFFER] (state, offer) {
    state.offer = offer
  },
  [REMOVE_PLANS] (state) {
    state.offerCards = []
  },
  [SET_CLOUD_PAYMENTS_PLAN] (state, plan) {
    state.cloudPaymentPlan = plan
  },
  [SET_ONE_MONTH_OFFER_PRICE] (state, plan) {
    state.onMonthPrice = plan.monthPrices.RUB
  },
  [SET_PROMO_USER] (state) {
    localStorage.setItem('promoUser', 'true')
    const promoUser = localStorage.getItem('promoUser')
    state.promoUser = promoUser
  },
  [REMOVE_SOC_PAY] (state) {
    state.paymentCards = state.paymentCards.filter(val => val.class !== 'social-pay')
  },
  [SET_TIER_TYPE] (state) {
    if (state.tierType) { return }

    const url = new URL(location.href)
    const tierType = url.searchParams.get('tier_type')

    if (tierType) { state.tierType = url.searchParams.get('tier_type') } else state.tierType = localStorage.getItem('tier') || 'default'
  },
  [SET_CLOSING_PAYMENT_COUNT] (state) {
    state.isFirstTimeClosingPayment = false
  },
  [SET_MESSAGE_PARAMS] (state) {
    const url = new URL(location.href)
    const messageId = url.searchParams.get('message_id')
    const campaignName = url.searchParams.get('utm_campaign')

    state.messageId = messageId
    state.campaignName = campaignName
  },
  [SET_SKELETON_LOAD_STATUS] (state, payload) {
    state.isSkeletonLoad = payload.isSkeletonLoad
    state.planIdErrorStatus = payload?.errorStatus
  },
  [SET_PLANS_LAST_PARAMS] (state, params) {
    state.plansLastParams = params
  }
}
